.background {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;

  &.border {
    .container, .row {
      height: 100%;
      
    }
    .row >div {
      height: 100%;
      border-right: 1px solid #E5E5E5;
      &:first-of-type {
        @media (min-width: 992px) {
          border-left: 1px solid #E5E5E5;
        }
      }
      &:nth-child(3) {
        @media (max-width: 991px) {
          border: none;
        }
      }
      &:nth-child(1) {
        @media (max-width: 767px) {
          border: none;
        }
      }
    }
  }
  
  .layer {
    -webkit-background-size: cover;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    height: 100%;
    left: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 100%;

    &.top-bg {
      background-size: auto;
      &.left {
        background-position: left top;
        @media (max-width: 991px) {
          background-size: 211px;
        }
        @media (max-width: 767px) {
          background-size:123px;
        }
      }
      &.right {
        background-position: right top;
        @media (max-width: 991px) {
          background-size: 150px;
        }
        @media (max-width: 767px) {
          background-size: 90px;
        }
      }
    }

    &.bottom-bg {
      @media (min-width: 992px) {
        width: 50%;
      }
      &.left {
        @media (min-width: 992px) {
          background-position: left bottom;
          background-size: 66%;
        }
        @media (max-width: 991px) {
          display: none;
        }
      }
      &.right {
        @media (min-width: 992px) {
          right:0;
          left: auto;
          background-position: right bottom;
          background-size: 89%;
        }
        @media (max-width: 991px) {
          display: none;
        }
      }

      &.center {
        display: none;
        background-position: left bottom;
        background-size: contain;
        @media (max-width: 991px) {
          display: block;
        }
      }
    }

  }
}

a {
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  text-decoration: none !important;
  outline: none !important;
} 

input,
button,
textarea {
  outline: none !important;
  transition: all 0.2s linear;
}

input {
  border: 1px solid #e6e6e6;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 20px;
  padding: 17px 18px 15px 18px;
  &:focus, &:active, &:focus:active {
    box-shadow: none !important;
  }
}

input { @include placeholder { 
  color: #7F7F7F;
  font-style: italic;
  font-weight: 300;
}}

textarea {
  border: 1px solid #e5e5e5;
  border-radius: 0;
  box-shadow: none;
  margin-bottom: 20px; 
  padding: 15px;
}



ul {
  margin: 0; 
  li {
    list-style: none;
  }
}

h1, h2, h3, h4, h5, h6 {
  color: #000;
}

h1 {
  @include rem(font-size, 40px);
  font-weight: 900;
  margin-bottom: 20px;

  @media(max-width: 767px) {
    @include rem(font-size, 30px);
  }
  
}

h2 {
  @include rem(font-size, 32px);
  margin-bottom: 20px;
}

h3 {
  @include rem(font-size, 24px);
  letter-spacing: 0.6px;
  margin-bottom: 20px;
}


/* style for input[type="radio"]
----------------------------------------------------*/
.wrap-radiobox,
.wrap-checkbox {
  position: relative;
}
input{
  &[type="radio"] {
    left: -9999px;
    position: absolute;
  }
  &[type="checkbox"] {
    left: -9999px;
    position: absolute;
    + label:before {
      background: #fff;
      content: ' ';
      display: inline-block;
      height: 30px;
      position: absolute;
      left: 0;
      top: 2px;
      width: 30px;
      border: 1px solid #b8b8b8;
    }
    + label:after {
      background: transparent;
      content: ' ';
      display: inline-block;
      height: 12px;
      position: absolute;
      left: 9px;
      top: 11px;
      width: 12px;
    }
    
  }
  &[type="radio"]:checked + label, &[type="checkbox"]:checked + label {
    color: $blue;
  }
  &[type="radio"]:checked + label:before, &[type="checkbox"]:checked + label:before {
    border-color: $blue;
  }
  &[type="radio"]:checked + label:after, &[type="checkbox"]:checked + label:after {
    background: $blue;
  }
  &[type="radio"] + label, &[type="checkbox"] + label {
    // color: #b6b6b6;
    cursor: pointer;
    @include rem(font-size, 16px);
    // font-weight: 800;
    margin-left: 30px;
    margin-left: 40px;
    line-height: 38px;
    @media(max-width: 767px) {
      @include rem(font-size, 14px);
    }
  }
  &[disabled="disabled"] + label {
    opacity: 0.4;
  }
  &[type="radio"] + label:before {
    background: #fff;
    content: ' ';
    display: inline-block;
    height: 30px;
    left: 0;
    position: absolute;
    top: 2px;
    width: 30px;
    border-radius: 50%;
    border: 1px solid #b8b8b8;
  }
  &[type="radio"] + label:after {
    background: #fff;
    content: ' ';
    display: inline-block;
    height: 12px;
    position: absolute;
    left: 9px;
    top: 11px;
    width: 12px;
    border-radius: 50%;
  }

}