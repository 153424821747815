header {
  min-height: 50px;
}

.custom-file-input::before {
  content: 'Завантажити';
}

.videoWrapper {
    max-width: 800px;
	margin: 0 auto;

	position: relative;
	padding-bottom: 39%; /* 16:9 */
	padding-top: 25px;
	height: 0;
}

.videoWrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
    width: 100%;
	height: 100%;
}

.highlight {
    background-color: yellow;
}

.has-search .form-control {
    padding-left: 3rem;
}

.has-search .form-control-feedback {
    position: absolute;
    z-index: 2;
    display: block;
    width: 2.375rem;
    height: 2.375rem;
    line-height: 2.375rem;
    text-align: center;
    pointer-events: none;
    color: #aaa;
}

.fa.fa-search {
    width: 47px;
    height: 47px;
    background: url("../img/icons/icon-search.svg") center center no-repeat;
    background-size: 36px 36px;
}

.dropdown-item {
    white-space: normal;
}
