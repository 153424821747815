.image {
  .logo {
    background: url('./../img/logo.png');
  }

  .big-logo {
    background: url('./../img/content/big-logo.png');
  }

  .icon-aid {
    background: url(./../img/icons/aid.svg);
  }

  .icon-pact {
    background: url(./../img/icons/pact.png);
  }

  .icon-mmsp {
    background: url(./../img/icons/mmsp.png);
  }

  .icon-mp {
    background: url(./../img/icons/mp.png);
  }

  .icon-woc {
    background: url(./../img/icons/woc.svg);
  }

  .icon-woc {
    background: url(./../img/icons/batton-up.svg);
  }
}

.default-user {
  background: url(./../img/default-user.svg);
}

.patter-left-corner {
  background-image: url(./../img/bg/patter_left_corner.png);
}

.patter-bottom-left {
  background-image: url(./../img/bg/patter_bottom-left.png);
}

.pattern-right-corner {
  background-image: url(./../img/bg/pattern_right_corner.png);
}

.patter-bottom-right {
  background-image: url(./../img/bg/patter_bottom-right.png);
}

.patter-bottom-tablet {
  background-image: url(./../img/bg/patter_bottom-tablet.png);
}

.bg-teteiv {
  background-image: url(./../img/bg/bg-teteiv.jpg);
}

.bg-teteiv-t {
  background-image: url(./../img/bg/bg-teteiv-t.jpg);
}

.bg-teteiv-m {
  background-image: url(./../img/bg/bg-teteiv-m.jpg);
}

.get-skills-1 {
  background-image: url(./../img/icons/get-skills-1.svg);
}

.get-skills-2 {
  background-image: url(./../img/icons/get-skills-2.svg);
}

.get-skills-3 {
  background-image: url(./../img/icons/get-skills-3.svg);
}

.get-skills-4 {
  background-image: url(./../img/icons/get-skills-4.svg);
}

.get-skills-5 {
  background-image: url(./../img/icons/get-skills-5.svg);
}

.get-skills-6 {
  background-image: url(./../img/icons/get-skills-6.svg);
}

.trainer-1 {
  background-image: url(./../img/content/trainer-1.jpg);
}

.trainer-2 {
  background-image: url(./../img/content/trainer-2.jpg);
}

.trainer-3 {
  background-image: url(./../img/content/trainer-3.jpg);
}

.trainer-4 {
  background-image: url(./../img/content/trainer-4.jpg);
}

.bg-1 {
  background-image: url(./../img/bg/bg-1.jpg);
}

.bg-1-m {
  background-image: url(./../img/bg/bg-1-m.jpg);
}

.bg-1-t {
  background-image: url(./../img/bg/bg-1-t.jpg);
}

.katoluk {
  background-image: url(./../img/content/katoluk.jpg);
}

.successful-1 {
  background-image: url(./../img/content/successful-1.png);
}

.successful-2 {
  background-image: url(./../img/content/successful-2.png);
}

.successful-3 {
  background-image: url(./../img/content/successful-3.png);
}

.successful-4 {
  background-image: url(./../img/content/successful-4.png);
}

.successful-5 {
  background-image: url(./../img/content/successfull-5.svg);
}

.img-1 {
  background-image: url(./../img/content/img-1.jpg);
}

.img-2 {
  background-image: url(./../img/content/img-2.jpg);
}

.aleks {
  background-image: url(./../img/content/aleks.jpg);
}

.yuzych {
  background-image: url(./../img/content/yuzych.jpg);
}

.katoluk {
  background-image: url(./../img/content/katoluk.jpg);
}

.skills-1 {
  background-image: url(./../img/icons/skills-1.svg);
}

.skills-2 {
  background-image: url(./../img/icons/skills-2.svg);
}

.skills-3 {
  background-image: url(./../img/icons/skills-3.svg);
}

.bu {
  background-image: url(./../img/bg/bu.png);
}

.doc-image {
  background-image: url(./../img/icons/doc.svg);
}

.sn-icon {
    background-image: url(./../img/icons/sn-icon.svg);
}
