// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~owl.carousel/dist/assets/owl.carousel.min.css';

@import "mixin";
@import "typography";
@import "main";
@import "humburger";

@import "header";

@import 'common';
@import 'images';
@import 'landing';
