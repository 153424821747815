* {
  margin: 0;
  padding: 0;
}

body {
  background: #fff;
  font-family: 'Fira Sans', sans-serif;
  font-size: 16px;
  color: #000;
  overflow-x: hidden;
  position: relative;
  &.hideOverflow {
    @media (max-width: 991px) and (min-width: 768px) {
      position: fixed !important;
      top: 0px;
      left: 0px;
      height: 100%;
      right: 0;
      bottom: 0;
    }
  }
}

.width-1280 {
  max-width: 1280px;
  margin: 0 auto;
  position: relative;
}

main {
  @media (min-width: 992px) {
    min-height: 250px;
  }
}

/**/
.tab {
  display: table;
  text-align: center;
  width: 100%;
  height: 100%;
}

.tab-row {
  display: table-row;
}

.tab-cell {
  display: table-cell;
  vertical-align: middle;
}

#wrapper {
  position: relative;
}

section {
  position: relative;
  z-index: 0;
}



/* Slide-menu
-----------------------------------------------------------------------*/
.slide-menu {
  background: #fff;
  padding: 25px 15px;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  width: 537px;
  z-index: 999;
  overflow-y: auto;
  box-shadow: 20px 0px 40px rgba(0, 0, 0, 0);
  -webkit-transform: translateX(-537px);
  -moz-transform: translateX(-537px);
  -o-transform: translateX(-537px);
  transform: translateX(-537px);
  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;

  @media(max-width: 767px) {
    box-shadow: none;
    opacity: 1;
    width: 100vw;
    -webkit-transform: translateX(-100vw);
    -moz-transform: translateX(-100vw);
    -o-transform: translateX(-100vw);
    transform: translateX(-100vw);
  }

  &.open {
    opacity: 1;
    box-shadow: 20px 0px 40px rgba(0, 0, 0, 0.15);
    -webkit-transform: translateX(0px);
    -moz-transform: translateX(0px);
    -o-transform: translateX(0px);
    transform: translateX(0px);
  }

  .menu-open {
    @media(min-width: 768px) {
      display: none;
    }
    // float: right;

  }

  .home {
    text-align: center;
    padding: 8px 28px 11px 28px;
    border: 2px solid #64A3D6;
    font-weight: 500;
    line-height: 28px;
    max-width: 185px;
    margin: 72px auto 92px;
    display: block;
    clear: both;
    @include rem(font-size, 20px);
    @media (max-width: 767px) {
      margin: 27px auto 30px;
    }

    @media(max-height:700px) and (min-width:992px) {
      margin: 20px auto 36px;
    }
  }

  .jump-to {
    @include rem(font-size, 21px);
    font-weight: 300;
    line-height: 28px;
    margin-bottom: 38px;
    text-align: center;
    @media (max-width: 767px) {
      margin-bottom: 25px;
      @include rem(font-size, 18px);
    }

  }

}

/* nav */
.primary-menu,
.slide-menu {
  nav {
    ul {
      li {
        text-align: center;
        a {
          line-height: 40px;
          @include rem(font-size, 30px);
          color: #000;
          position: relative;
          display: inline-block;
          margin-bottom: 49px;

          @media (max-width: 767px) {
            line-height: 24px;
            margin-bottom: 28px;
          }
          @media (max-width: 640px) {
            @include rem(font-size, 24px);
          }
          @media (max-width: 515px) {
            @include rem(font-size, 18px);
          }

          @media(max-height:700px) and (min-width:992px) {
            @include rem(font-size, 24px);
            margin-bottom: 24px;
          }

          &:after {
            content:' ';
            height: 0;
            width: 100%;
            transition: all 0.25s ease-in-out 0s;
            background: #ffe7a0;
            position: absolute;
            left: 0;
            bottom: -10px;
            z-index: -1;
          }

          &.active, &:hover {
            &:after {
              height: 15px;
              bottom: 0;
            }
          }

          @media (max-width: 767px) {
            &.active:after,
            &:hover:after {
              height: 7px;
              bottom: 2px;
            }
          }
        }
        &:last-of-type a {
          margin-bottom: 0;
        }
      }
    }
  }
}

/* Header
-----------------------------------------------------------------------*/
#wrapper {
  overflow: hidden;
  position: relative;

  -webkit-transition: all 0.35s ease-in-out 0s;
  -moz-transition: all 0.35s ease-in-out 0s;
  -o-transition: all 0.35s ease-in-out 0s;
  transition: all 0.35s ease-in-out 0s;

  &.open {
    @media(min-width: 768px) {
      -webkit-transform: translateX(537px);
      -moz-transform: translateX(537px);
      -o-transform: translateX(537px);
      transform: translateX(537px);
    }
    @media(max-width: 767px) {
      // -webkit-transform: translateX(100vw);
      // -moz-transform: translateX(100vw);
      // -o-transform: translateX(100vw);
      // transform: translateX(100vw);
    }
  }
}

/* Header
-----------------------------------------------------------------------*/
.menu-open.hed {
  left: 53px;
  top: 25px;
  position: fixed;
  z-index: 10;
  transition: all 0.35s ease-in-out 0s;
  @media (max-width: 991px) {
    left: 15px;
  }
  &.open {
    @media (min-width: 768px) {
      position: fixed;
      left: 590px;
    }
  }
}
header {
  padding-top: 25px;
  padding-bottom: 30px;

  @media (max-width: 767px) {
    // padding-top: 25px;
    // padding-bottom: 25px;
    // position: fixed;
    // top: 0;
    // left: 0;
    // width: 100%;
    // z-index: 10;
    // border-bottom: 1px solid #ebebeb;

    .menu-open.open {
      opacity: 0;
    }
  }

  // .menu-open {
  //   left: 53px;
  //   top: 25px;
  //   position: fixed;
  //   z-index: 10;
  //   &.open {
  //     position: fixed;
  //   }
  // }

  .container-fluid {
    @media (min-width: 992px) {
      padding-left: 53px;
      padding-right: 53px;
    }
  }

  .wrap-logo {
    cursor: pointer;
    text-align: center;
    @media(max-width: 767px) {
      text-align: right;
    }
    .slogan {
      padding-top: 32px;
      display: inline-block;
      max-width: 282px;
      text-align: right;
      vertical-align: top;
      margin-right: 16px;
      @include rem(font-size, 22px);
      // font-weight: 700;
      line-height: 20px;

      @media(max-width: 1149px) {
        @include rem(font-size, 10px);
        max-width: 141px;
        margin-right: 8px;
        padding-top: 21px;
        line-height: normal;
      }
    }
    .logo-box {
      display: inline-block;
      vertical-align: top;
      @media(max-width: 1149px) {
        padding-top: 7px;
      }
      @media(max-width: 375px) {
        padding-top: 11px;
      }
      img {
        width: 135%;
        @media(max-width: 1149px) {
          max-width: 80px;
          width: 100%;
          height: auto;
        }
        @media(max-width: 375px) {
          max-width: 55px;
        }
      }
    }
  }

  .links-box {
    text-align: right;
    @media(max-width: 1149px) {
      padding-right: 25px;
    }
    @media(max-width: 767px) {
      display: none;
    }
  }
  .tel, .mailto, .site-link {
    display: block;
    color: #000;
  }
  .tel {
    @include rem(font-size, 27px);
    @media(max-width: 1149px) {
      @include rem(font-size, 18px);
      padding-top: 5px;
    }
  }
  .mailto {
    padding-top: 14px;
    @include rem(font-size, 21px);
    @media(max-width: 1149px) {
      @include rem(font-size, 14px);
    }
  }
  .site-link {
    @include rem(font-size, 18px);
    @media(max-width: 1149px) {
      @include rem(font-size, 12px);
    }
  }
}

/* box-title
-----------------------------------------------------------------------*/
.box-title {
  line-height: normal;
  @include rem(font-size, 51px);
  line-height: 61px;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 22px;

  @media (max-width: 991px) {
    @include rem(font-size, 41px);
    line-height: 49px;
    margin-bottom: 35px;
  }
  @media (max-width: 767px) {
    @include rem(font-size, 27px);
    line-height: 32px;
    margin-bottom: 25px;
  }
}

/* primary-box
-----------------------------------------------------------------------*/
.primary-box {

  .prim-height {
    min-height: calc(100vh - 167px);
    @media (max-width: 1199px) {
      min-height: calc(100vh - 129px);
    }
    @media (max-width: 991px) {
      min-height: 520px;
    }
  }

  .primary-menu {
    @media (max-width: 767px) {
      padding-bottom: 360px;
    }

    .primary-box-bg {

      background-image: url(./../img/bg/primary-bg.png);

      width: 677px;
      height: 414px;
      bottom: 0;
      top: auto;
      left: auto;
      right:-157px;
      background-position: 50% bottom;
      @media (max-width: 991px) {
        width: 421px;
        height: 258px;
        right: 0;
        bottom: 120px;
      }
      @media (max-width: 767px) {
        width: 334px;
        height: 207px;
        right: calc(50% - 167px);
        bottom: 130px;
        background-size: 100%;
      }
      @media (max-width: 354px) {
        width: 280px;
        right: calc(50% - 140px);
      }
    }

    nav {
      margin-top: 85px;

      @media(max-height:700px) and (min-width:992px) {
        margin-top: 20px;
      }

      @media (max-width: 991px) {
        margin-top: 22px;
      }
      @media (max-width: 767px) {
        margin-top: 12px;
      }

      ul li {
        text-align: left;

        a {
          @include rem(font-size, 51px);
          line-height: 67px;
          margin-bottom: 36px;

          @media(max-height:700px) and (min-width:992px) {
            @include rem(font-size, 40px);
            margin-bottom: 10px;
          }

          @media (max-width: 1199px) {
            @include rem(font-size, 42px);
          }

          @media (max-width: 991px) {
            @include rem(font-size, 30px);
            line-height: 40px;
            margin-bottom: 21px;
          }

          @media (max-width: 767px) {
            line-height: 24px;
            margin-bottom: 28px;
          }
          @media (max-width: 640px) {
            @include rem(font-size, 24px);
          }
          @media (max-width: 515px) {
            @include rem(font-size, 18px);
          }

          @media (min-width: 992px) {
            &.active:after,
            &:hover:after {
              height: 12px;
              bottom: 13px;
            }
          }
          @media (max-width: 767px) {
            &.active:after,
            &:hover:after {
              height: 7px;
              bottom: 2px;
            }
          }

        }
      }

    }

    .scroll-box {
      position: absolute;
      bottom: 0;
      left:calc(50% - 8px);
      height: 93px;
      width: 16px;
      background: url(../img/icons/mouse.svg) no-repeat center top, url(../img/icons/arrow-down.svg) no-repeat center bottom;
    }

  }
}

/* facebook-news
-----------------------------------------------------------------------*/
.facebook-news {
  padding-top: 83px;
  .facebook-box-news {
    margin-bottom: 15px;
    img {
      width: 100%;
      height: auto;
    }

    &:nth-child(3) {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }
}

/* facebook-news
-----------------------------------------------------------------------*/
.youth-council-uk {
  padding-top: 85px;
  margin-bottom: 90px;
  @media (max-width: 991px) {
    margin-bottom: 30px;
  }

  .add-youth-council {
    width: 397px;
    height: 72px;
    border: 2px solid #64A3D6;
    font-weight: 500;
    line-height: 28px;
    @include rem(font-size, 21px);
    margin: 0 auto 27px;

    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    @media (max-width: 991px) {
      width: 273px;
      height: 50px;
      @include rem(font-size, 14px);
      line-height: 18px;
    }
  }

  .scroll-down {
    height: 60px;
    width: 6px;
    margin: 0 auto 29px;
    background: url(../img/icons/arrow-down.svg) no-repeat center bottom;
    @media (max-width: 991px) {
      height: 30px;
      margin-bottom: 20px;
    }
  }

  .wrap-map {
    height: 600px;
    width: 100%;
    overflow: hidden;
    box-shadow: inset 0px 0px 6px rgba(0, 0, 0, 0.25);
    @media (max-width: 991px) {
      height: 500px;
    }
  }
}



/* button-up
-----------------------------------------------------------------------*/
.button-up {
  position: fixed;
  right: 80px;
  bottom: 100px;
  display: none;
  transition: all 0.2s linear;
  @media (max-width: 991px) {
    right: 22px;
  }
  @media (max-width: 767px) {
    display: none !important;
  }
  &:hover {
    opacity: 0.8;
  }
}

/* wrap-steps
-----------------------------------------------------------------------*/
.wrap-steps {

  .box-title {
    margin-bottom: 56px;
    margin-top: 22px;
    @media (max-width: 991px) {
      margin-bottom: 40px;
      margin-top: 18px;
    }
  }

  .wrap-accardion {
    padding-left: 48px;
    margin-bottom: 70px;
    position: relative;
    @media (max-width: 991px) {
      margin-bottom: 42px;
      padding-left: 30px;
    }
    &:before {
      content: ' ';
      height: 110px;
      width: 5px;
      position: absolute;
      left: 48px;
      top: -105px;
      @media (max-width: 991px) {
        left: 30px;
      }

      /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#64a3d6+0,ffe9a3+100 */
      background: rgb(100,163,214); /* Old browsers */
      background: -moz-linear-gradient(top, rgba(100,163,214,1) 0%, rgba(255,233,163,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(top, rgba(100,163,214,1) 0%,rgba(255,233,163,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to bottom, rgba(100,163,214,1) 0%,rgba(255,233,163,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#64a3d6', endColorstr='#ffe9a3',GradientType=0 ); /* IE6-9 */

      // border-left: 5px solid transparent;
      // -moz-border-image: -moz-linear-gradient(top, rgba(100,163,214,1) 0%, rgba(255,233,163,1) 100%);
      // -webkit-border-image: -webkit-linear-gradient(top, rgba(100,163,214,1) 0%, rgba(255,233,163,1) 100%);
      // border-image: linear-gradient(to bottom, rgba(100,163,214,1) 0%, rgba(255,233,163,1) 100%);
      // border-image-slice: 1;
    }


    .ac-title {
      line-height: 41px;
      @include rem(font-size, 30px);
      background: $yellow;
      display: inline-block;
      padding: 18px 33px;
      margin: 0 26px 40px -35px;
      @media (max-width: 767px) {
        line-height: 29px;
        @include rem(font-size, 21px);
        font-weight: 700;
        padding: 22px 23px;
        margin: 0 0px 21px -17px;
        letter-spacing: 0;
      }
    }

    .open-all {
      color: #2280CB;
      font-weight: 300;
      line-height: 28px;
      @include rem(font-size, 21px);
      // border-bottom: 1px solid #2280CB;
      position: relative;

      @media (min-width: 768px) {
        display: inline-block;
        white-space: nowrap;
        float: right;
        margin-bottom: 40px;
      }

      @media (max-width: 767px) {
        float: right;
        margin-bottom: 20px;
        @include rem(font-size, 18px);
        line-height: 24px;
      }
      &:after {
        content: ' ';
        height: 1px;
        width: 100%;
        transition: all 0.25s ease-in-out 0s;
        background: #2280CB;
        position: absolute;
        left: 0;
        bottom: 0px;
        z-index: -1;
      }
      &:hover:after  {
        height: 0;
        bottom: -2px;
      }
    }

    .accordion {
      border-left: 5px solid $yellow;
      position: relative;
      clear: both;
      @media (max-width: 767px) {
        clear: both;
      }
      &:before {
        content: ' ';
        height: 50px;
        width: 6px;
        // background: $yellow;
        background: none !important;
        border-left: 5px solid $yellow;
        position: absolute;
        left: -5px;
        top: -45px;
        @media (min-width: 768px) {
          height: 150px;
          top: -113px;
          z-index: -1;
        }
        @media (max-width: 767px) {
          height: 85px;
          top: -75px;
        }
      }

      .card {
        margin-bottom: 31px;
        background: none;
        border: 5px solid $yellow;
        border-right: 50px solid $yellow;
        margin-left: 42px;
        border-radius: 0;
        @media (max-width: 991px) {
          border-right: 38px solid $yellow;
        }
        @media (max-width: 767px) {
          margin-left: 27px;
        }
      }

      .card-header {
        padding: 0;
        background: none;
        border: none;
        position: relative;
        background: #fff;
        z-index: 2;
        span {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: inline-block;
          background: $yellow;
          text-align: center;
          color: white;
          font-weight: 400;
          line-height: 50px;
          @include rem(font-size, 30px);

          position: absolute;
          left: -73px;
          top: calc(50% - 25px);

          @media (max-width: 767px) {
            width: 40px;
            height: 40px;
            @include rem(font-size, 21px);
            line-height: 40px;
            left: -55px;
          }

          &:after {
            content:"";
            width: 23px;
            height: 5px;
            background: $yellow;
            position: absolute;
            top:calc(50% - 2.5px);
            right:-23px;
            @media (max-width: 767px) {
              right: -14px;
              width: 18px;
            }
          }
        }

        .btn-link {
          min-height: 102px;
          white-space: normal;
          line-height: 36px;
          @include rem(font-size, 27px);
          padding-left: 24px;
          padding-right: 50px;
          text-align: left;
          width: calc(100% + 48px);
          color: #000;
          text-decoration: none !important;
          border-radius: 0;
          position: relative;
          @media (max-width: 991px) {
            padding-left: 13px;
            width: calc(100% + 36px);
          }
          @media (max-width: 767px) {
            @include rem(font-size, 18px);
            line-height: normal;
          }

          &:before {
            content:"";
            width: calc(100% + 6px);
            height: 5px;
            background: $yellow;
            position: absolute;
            bottom:-5px;
            left:-3px;
            z-index: -1;
          }

          &:after {
            content: "";
            width: 50px;
            height: 100%;
            position: absolute;
            right: -2px;
            top: 0;
            background: url(../img/icons/accardion-arrow.svg) no-repeat center center;
            background-size: 28px;
            @media (max-width: 991px) {
              width: 38px;
              background-size: 24px;
            }
          }
          &[aria-expanded="true"]{
            &:after {
              transform: rotate(180deg);
            }
          }
        }
      }

      .collapse {
        .card-body {
          font-weight: 300;
          line-height: 28px;
          @include rem(font-size, 21px);
          padding: 1.8125rem;
          background: #fff;
          @media (max-width: 991px) {
            padding: 24px 13px;
          }
          @media (max-width: 767px) {
            line-height: 21px;
            @include rem(font-size, 16px);
          }

          p {
            margin-bottom: 0;
          }
        }
      }

    }

    /**/


    &:nth-child(3n+1) {

      &:before {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#b1d588+0,64a3d6+100 */
        background: rgb(177,213,136); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(177,213,136,1) 0%, rgba(100,163,214,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(177,213,136,1) 0%,rgba(100,163,214,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(177,213,136,1) 0%,rgba(100,163,214,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b1d588', endColorstr='#64a3d6',GradientType=0 ); /* IE6-9 */
      }
      .accordion .card,
      .accordion {
        border-color: $blue;
      }
      .accordion .card-header .btn-link:before,
      .accordion .card-header span:after,
      .accordion:before,
      .ac-title,
      .card-header span {
        background: $blue;
        border-color: $blue;
      }
    }

    /**/
    &:nth-child(3n) {
      &:before {
        /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffe9a3+0,b1d588+100 */
        background: rgb(255,233,163); /* Old browsers */
        background: -moz-linear-gradient(top, rgba(255,233,163,1) 0%, rgba(177,213,136,1) 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, rgba(255,233,163,1) 0%,rgba(177,213,136,1) 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, rgba(255,233,163,1) 0%,rgba(177,213,136,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffe9a3', endColorstr='#b1d588',GradientType=0 ); /* IE6-9 */
      }
      .accordion .card,
      .accordion {
        border-color: $green;
      }
      .accordion .card-header .btn-link:before,
      .accordion .card-header span:after,
      .accordion:before,
      .ac-title,
      .card-header span {
        background: $green;
        border-color: $green;
      }
    }

    &:last-of-type {
      .accordion .card:last-of-type {
        &:after {
          content: "";
          width: 10px;
          height: calc(100% - 50px);
          position: absolute;
          left: -53px;
          top: 76px;
          background: #fff;
          @media (max-width: 767px) {
            left: -40px;
            top: 66px;
          }
        }
      }
    }

  }

}

.wrap-accardion:nth-child(2) {
  &:before {
    display: none;
  }
}

.img-steps-page {
  max-width: 864px;
  margin: 0 auto 20px;
  @media (max-width: 991px) {
    max-width: 488px;
  }
  @media (max-width: 767px) {
    max-width: 308px;
    margin-bottom: 10px;
  }
  img {
    max-width: 100%;
  }
}

/* add-form
-----------------------------------------------------------------------*/
.wrap-add-form {
  padding-top: 40px;
  @media (max-width: 991px) {
    padding-top: 30px;
    padding-bottom: 13px;
  }
  @media (max-width: 991px) {
    padding-top: 11px;
    padding-bottom: 17px;
  }

  .box-title {
    margin-bottom: 36px;
    @media (max-width: 991px) {
      margin-bottom: 42px;
    }
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
  }
}
.add-form {
  max-width: 695px;
  margin: 0 auto;
  @media (max-width: 991px) {
    max-width: 598px;
  }
}
form {
  label {
    font-weight: 500;
    line-height: 28px;
    @include rem(font-size, 18px);
    color: #7F7F7F;
    &[for="geo-y"] {
      @media(min-width: 768px) {
        height: 17px;
      }
      @media(min-width: 992px) {
        height: 21px;
      }
    }
    @media (max-width: 991px) {
      line-height: 24px;
      @include rem(font-size, 15.5px);
    }
    @media (max-width: 767px) {
      line-height: 22px;
      @include rem(font-size, 14px);
    }
  }
  input {
    width: 100%;
    background: #FFFDFD;
    border: 1px solid #C4C4C4;
    border-radius: 3px;
    height: 52px;
    @include rem(font-size, 18px);
    color: #7F7F7F;
    font-style: italic;
    font-weight: 300;
    margin-bottom: 0;
    padding-left: 21px;
    @media (max-width: 991px) {
      height: 44px;
      line-height: 20px;
      @include rem(font-size, 15.5px);
    }
    @media (max-width: 767px) {
      line-height: 18px;
      @include rem(font-size, 14px);
    }
  }
  .blue-link {
    @media(min-width:992px) {
      margin-top: 43px;
    }
    @media(min-width:768px) {
      margin-top: 43px;
    }
    @media(max-width:767px) {
      margin-bottom: 15px;
    }
  }
  button {
    background: #FFE7A1;
    border: 2px solid #64A3D6;
    box-sizing: border-box;
    max-width: 397px;
    width: 100%;
    height: 72px;
    display: block;
    margin: 28px auto;
    font-weight: 500;
    line-height: 28px;
    @include rem(font-size, 21px);
    text-align: center;
    text-transform: uppercase;
    padding-left: 28px;
    padding-right: 28px;
    cursor: pointer;
    &:hover {
      border-color: #043a67;
    }
    @media (max-width: 991px) {
      width: 275px;
      height: 50px;
      line-height: 18px;
      @include rem(font-size, 14px);
      margin: 24px auto;
    }
    @media (max-width: 767px) {
      margin: 14px auto;
    }
  }

  .form-group {
    margin-bottom: 31px;
    @media (max-width: 991px) {
      margin-bottom: 26px;
    }
    &.wrap-geo-x {
      @media (max-width: 767px) {
        margin-bottom: 3px;
      }
    }
  }

  textarea {
    background: #FFFDFD;
    border: 1px solid #C4C4C4;
    box-sizing: border-box;
    border-radius: 3px;
    width: 100%;
    min-height: 177px;
    @media (max-width: 991px) {
      min-height: 151px;
    }
  }

}

.btn-sm {
  height: auto;
  width: auto;
  margin: 0;
  padding: .375rem .75rem;
  font-size: 1rem;
  line-height: 1.5;
}

.wrap-select {
  width: 100%;
  min-width: 180px;
  background: #FFFDFD;
  border: 1px solid #C4C4C4;
  border-radius: 3px;
  height: 52px;
  position: relative;
  z-index: 2;
  @media (max-width: 991px) {
    height: 44px;
  }
  &:after {
    position: absolute;
    right: 0;
    top: 0;
    width: 50px;
    height: 100%;
    content: " ";
    z-index: -1;
    background: url(../img/icons/select.svg) no-repeat center center;
    background-color: #FFFDFD;
    background-size: 18px;
  }

  select {
    margin: 0;
    display: block;
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
    padding: 12px 55px 15px 15px;
    @include rem(font-size, 18px);
    color: #7F7F7F;
    font-style: italic;
    font-weight: 300;
    padding-left: 21px;

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    @media (max-width: 991px) {
      height: 44px;
      line-height: 20px;
      @include rem(font-size, 15.5px);
    }
    @media (max-width: 767px) {
      line-height: 18px;
      @include rem(font-size, 14px);
    }
  }
}

.blue-link {
  color: #2280CB;
  font-weight: 300;
  line-height: 28px;
  @include rem(font-size, 18px);
  position: relative;
  font-style: italic;
  font-weight: 300;
  display: inline-block;
  @media (max-width: 991px) {
    line-height: 20px;
    @include rem(font-size, 15.5px);
  }
  @media (max-width: 767px) {
    line-height: 18px;
    @include rem(font-size, 14px);
  }
  &:after {
    content: ' ';
    height: 1px;
    width: 100%;
    transition: all 0.25s ease-in-out 0s;
    background: #2280CB;
    position: absolute;
    left: 0;
    bottom: 0px;
    z-index: -1;
  }
  &:hover:after {
    height: 0;
    bottom: -2px;
  }
}

/* about-project
-----------------------------------------------------------------------*/
.about-project {
  overflow: hidden;

  >.container {
    position: relative;
  }

  .background .logo-q {

    background-image: url(../img/bg/logo-q.png);

    width: 708px;
    height: 586px;
    background-size: 100%;
    top: 206px;
    left: auto;
    right: -154px;
    @media (min-width: 992px) and (max-width: 1199px) {
      width: 550px;
    }
    @media (max-width: 991px) {
      width: 440px;
      top: -49px;
      right: -45px;
    }
    @media (max-width: 767px) {
      width: 302px;
      top: 145px;
      right: 15px;
    }
  }

  .box-title {
    margin-top: 25px;
    margin-bottom: 34px;
    &.left {
      text-align: left;
      @media (max-width: 991px) {
        margin-bottom: 31px;
      }
      @media (max-width: 767px) {
        margin-bottom: 12px;
        margin-top: 20px;
      }
    }
  }

  p {
    font-weight: 300;
    line-height: 41px;
    @include rem(font-size, 30px);
    margin-bottom: 44px;
    @media (max-width: 991px) {
      line-height: 23px;
      @include rem(font-size, 17px);
      margin-bottom: 25px;
    }
    @media (max-width: 767px) {
      line-height: 22px;
      @include rem(font-size, 16px);
      margin-bottom: 25px;
    }
  }

  .doc {
    max-width: 752px;
    margin-top: 63px;
    margin-bottom: 104px;
    padding-top: 34px;
    padding-bottom: 1px;
    position: relative;
    @media (max-width: 991px) {
      margin-top: 69px;
      max-width:590px;
      padding-top: 17px;
      padding-left: 123px;
      margin-bottom: 57px;
    }
    @media (max-width: 767px) {
      margin-top: 170px;
      padding-left: 15px;
      margin-bottom: 37px;
    }

    &:before {
      content: "";
      border: 10px solid #B1D588;
      width:400%;
      height: 100%;
      position: absolute;
      right:130px;
      top: 0;
      z-index: -1;
      @media (max-width: 991px) {
        border: 5px solid #B1D588;
      }
      @media (max-width: 767px) {
        right: 27px;
      }
    }
    p {
      font-weight: 600;
      line-height: 41px;
      @include rem(font-size, 30px);
      background: #fff;
      position: relative;
      z-index: 1;
      padding-top: 13px;
      padding-bottom: 10px;
      @media (max-width: 991px) {
        @include rem(font-size, 17px);
        line-height: 23px;
      }
      @media (max-width: 767px) {
        line-height: 19px;
        @include rem(font-size, 14px);
      }
    }
  }

  .autors {
    overflow: hidden;
    max-width: 593px;
    float: right;
    font-weight: 300;
    line-height: 41px;
    @include rem(font-size, 30px);
    text-align: center;
    border: 10px solid #FFE7A1;
    margin-bottom: 58px;
    padding: 32px 10px 42px 10px;
    @media (max-width: 991px) {
      max-width: 348px;
      border: 5px solid #FFE7A1;
      line-height: 23px;
      @include rem(font-size, 17px);
      padding: 19px 10px 29px 10px;
    }
    @media (max-width: 767px) {
      line-height: 19px;
      @include rem(font-size, 14px);
      padding: 15px 16px 17px 16px;
      margin-bottom: 35px;
    }
  }

  .questions-title {
    margin-bottom: 51px;
    @media (max-width: 991px) {
      margin-bottom: 30px;
    }
  }
}

/* footer
-----------------------------------------------------------------------*/
footer {
  padding-top: 85px;
  padding-bottom: 5px;
  @media (max-width: 991px) {
    padding-top: 30px;
  }
  @media (max-width: 767px) {
    padding-top: 37px;
  }

  p.within-program {
    @include rem(font-size, 21px);
    line-height: 18px;
    text-align: center;
    font-weight: 300;
    line-height: 33px;
    margin-bottom: 52px;
    @media (max-width: 991px) {
      margin-bottom: 62px;
    }
    @media (max-width: 767px) {
      @include rem(font-size, 18px);
      line-height: 28px;
      margin-bottom: 53px;
    }
  }

  .foot-logos {
    >div {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      max-width: 100%;
      @media (max-width: 767px) {
        max-width: 78%;
        margin-bottom: 25px;
      }

    }
  }

  .cont-info {
    padding-top: 23px;
    text-align: center;
    @media (max-width: 991px) {
      padding-top: 42px;
    }

    .tel, .mail {
      line-height: 45px;
      font-size: 27px;
      color: #000;
      display: inline-block;
      @media (max-width: 767px) {
        line-height: 30px;
        @include rem(font-size, 21px);
        padding-top: 18px;
      }
    }

    .addr {
      padding-top: 15px;
      font-weight: 300;
      line-height: 35px;
      @include rem(font-size, 21px);
      margin-bottom: 18px;
      @media (max-width: 991px) {
        margin-bottom: 13px;
      }
      @media (max-width: 767px) {
        line-height: 27px;
        @include rem(font-size, 16px);
        padding-top: 20px;
      }
    }

    .cop {
      text-align: left;
      font-weight: 300;
      line-height: 27px;
      @include rem(font-size, 16px);
      margin-bottom: 18px;
      @media (max-width: 991px) {
        display: inline-block;
        width: calc(50% - 4px);
        text-align: left;
      }
      @media (max-width: 767px) {
        display: block;
        width: 100%;
        text-align: center;
        margin-bottom: 81px;
        line-height: 24px;
        @include rem(font-size, 14px);
      }
    }

    .created {

      span {
        padding-right: 20px;
      }

      @media (min-width:1200px) {
        position: absolute;
        right: 0;
        bottom: -10px;
      }
      margin-bottom: 18px;
      font-weight: 300;
      line-height: 27px;
      @include rem(font-size, 16px);

      @media (max-width: 991px) {
        display: inline-block;
        width: calc(50% - 4px);
        text-align: right;
      }
      @media (max-width: 767px) {
        display: block;
        width: 100%;
        text-align: center;
        line-height: 24px;
        @include rem(font-size, 14px);
      }
    }
  }
}

/*---------------------------------------------------------*/
/*----------------------- news-carousel -------------------*/
/*---------------------------------------------------------*/
.news-carousel {
  padding: 0 10px;

  .owl-stage {
    @media(min-width:576px) {
      display: flex;
      flex-wrap: wrap;
      >div {
        display: flex;
      }
    }
  }

  .news-item {
    padding-bottom: 220px;
    position: relative;

    h3 {
      font-weight: 500;
      line-height: 28px;
      font-size: 18px;
      margin-bottom: 20px;
      @media(max-width:575px) {
        height: auto !important;
      }
      a {
        display: block;
        color: #000;
        &:hover {
          color: $blue;
        }
      }
    }
    .text-box {
      margin-bottom: 30px;
    }
    .date {
      position: absolute;
      left: 0;
      bottom: 190px;
      font-weight: 300;
      line-height: 25px;
    }
    .img-box {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 178px;
      width: 100%;
      display: block;
      background-position: center center;
      background-size: cover;
    }
  }

  .owl-nav {
    button {
      width: 28px;
      height: 55px;
      position: absolute;
      top: calc(50% - 25px);
      transition: all 0.2s linear;
      span {
        display: none;
      }
      @media(max-width:1300px) {
        width: 22px;
        height: 45px;
        opacity:1;
        top: -80px;
        &:hover {
          opacity: 0.9;
        }
      }
      @media(max-width:767px) {
        width: 15px;
        height: 30px;
        top: -60px;
      }
      &.owl-prev {
        left:-35px;
        background:url(../img/icons/arrow-left.svg) no-repeat center center;
        @media(max-width:1300px) {
          left: auto;
          right:54px;
          background-size: cover;
        }
        @media(max-width:767px) {
          right: 40px;
        }
      }
      &.owl-next {
        right:-35px;
        background:url(../img/icons/arrow-right.svg) no-repeat center center;
        @media(max-width:1300px) {
          right: 15px;
          background-size: cover;
        }
      }
    }
  }

  .owl-dots {
    text-align: center;
    padding-top: 50px;
    button {
      display: inline-block;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      border-radius: 50%;
      border: 3px solid $blue;
      transition: all 0.2s linear;
      background-color: white;

      &.active, &:hover {
        background-color: $blue;
      }
    }
  }
}

.news-page {
  .back-link {
    font-style: italic;
    display: inline-block;
    font-weight: 300;
    line-height: 28px;
    font-size: 21px;
    text-decoration-line: underline !important;
    color: #2280CB;
    margin-bottom: 35px;
    padding-left: 45px;
    background: url(../img/icons/arrow-left2.svg) no-repeat left center;
  }
  .date {
    font-weight: 300;
    line-height: 25px;
    font-size: 16px;
    margin-bottom: 40px;
  }
  .img-news {
    margin-bottom: 40px;
    img {
      max-width:100%;
    }
  }
}

.cicle-list li:before {
  background: url(../img/icons/g2.svg) no-repeat;
  border: none;
  border-radius:0;
}
.cicle-list.blue li:before {
  background: url(../img/icons/g1.svg) no-repeat;
  border: none;
  border-radius:0;
}

.downloads-doc {
  color: #2280CB;
  font-weight: 300;
  line-height: 41px;
  font-size: 30px;
  margin-bottom: 56px;
  display: inline-block;
  img {
    position: relative;
    top: -2px;
    margin-right: 10px;
  }
}

.after-title {
  .downloads-doc {
    margin-left: 20px;
    margin-bottom: 0;
  }
}

// .step-by-step {
//   .downloads-doc {
//     color: #2280CB;
//   }
// }

// .primary-box .primary-menu nav ul li a {
//   &.downloads-doc {
//     font-size: 30px;
//   }
// }

// .primary-box .primary-menu nav ul li.step-by-step a:not(.downloads-doc) {
//   margin-bottom: 0;
// }
