
.menu-open {
  width: 40px;
  height: 40px;
  margin: 12px 0px 0px 17px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  background: none !important;
  border: none !important;
  border-radius: 0;
  @media(max-width: 991px) {
    margin: 12px 0px 0px 9px;
  }
  @media(max-width: 767px) {
    margin: 12px 0px 0px 0px;
  }
}

.menu-open span {
  display: block;
  position: absolute;
  height: 4.5px;
  width: 100%;
  background: #000;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
  box-shadow: 0 0px 24px rgba(0, 0, 0, 0.2);
}

/* Icon 3 */

.menu-open span:nth-child(1) {
  top: 10px;
}

.menu-open span:nth-child(2),.menu-open span:nth-child(3) {
  top: 22px;
}

.menu-open span:nth-child(4) {
  top: 35px;
  width: 55%;   
}

.menu-open.open span:nth-child(1) {
  top: 18px;
  width: 0%;
  left: 50%;
}

.menu-open.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

.menu-open.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.menu-open.open span:nth-child(4) {
  top: 18px;
  width: 0%;
  left: 50%;
}
