.wrap-logo {
    display: flex;
    align-items: center;
}
.site-slogan {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    @media (max-width: 767px) {
        margin-top: 15px;
    }
    .icon-box {
        animation: animate-element 18s linear infinite;
        width: 60px;
        margin-right: 12px;
        @media (max-width: 991px) {
            width: 44px;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    .text-wrap {
        font-weight: 600;
        text-transform: uppercase;
        font-size: 18px;
        line-height: 1.1;
        text-align: left;
        @media (max-width: 991px) {
            font-size: 12px;
            line-height: 15px;
        }
    }
}

@keyframes animate-element {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(90deg);
    }

    75% {
        transform: rotate(270deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
