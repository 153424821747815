/* about-page
---------------------------------------------------------------------------*/
.about-page {
  padding-top: 17px;
	padding-bottom: 64px;
	@media (max-width: 991px) {
		padding-top: 32px;
		padding-bottom: 15px;
	}
	@media (max-width: 767px) {
		padding-top: 21px;
		padding-bottom: 23px;
	}

	@media(max-height:700px) and (min-width:992px) {
		padding-top: 18px;
		padding-bottom: 40px;
	}

	.box-title {
		max-width: 782px;
		margin: 0 auto 17px;
		@media (max-width: 991px) {
	    margin-bottom: 3px;
		}
		@media (max-width: 767px) {
	    margin-bottom: 11px;
		}
	}
	.after-title {
		font-weight: 300;
		line-height: 41px;
		@include rem(font-size, 30px);
		text-align: center;
		
		@media (max-width: 991px) {
			max-width: 350px;
			margin: 0 auto;
			line-height: 29px;
			@include rem(font-size, 21px);
		}

		@media (max-width: 767px) {
			line-height: 22px;
			@include rem(font-size, 16px);
		}
	}
}

/* how-organize
---------------------------------------------------------------------------*/
.how-organize {
	padding-top: 142px;
	padding-bottom: 152px;
	@media (max-width: 991px) {
		padding-top: 79px;
		padding-bottom: 118px;
	}
	@media (max-width: 767px) {
		padding-top: 45px;
		padding-bottom: 21px;
	}

	@media(max-height:700px) and (min-width:992px) {
		padding-top: 40px;
		padding-bottom: 40px;
	}

	.box-title {
		margin-bottom: 31px;
		text-transform: none;
		@media (min-width: 992px) {
			line-height: 67px;
		}
		@media (max-width: 991px) {
			line-height: 53px;
			margin-bottom: 46px;
		}
		@media (max-width: 767px) {
			line-height: 35px;
			margin-bottom: 19px;
		}

		@media(max-height:700px) and (min-width:992px) {
			line-height: 56px;
			@include rem(font-size, 44px);
		}
	}

	.after-title {
		font-weight: 300;
		line-height: 41px;
		@include rem(font-size, 30px);
		margin-bottom: 28px;	
		text-align: center;
		color: white;
		@media (max-width: 991px) {
			line-height: 29px;
			@include rem(font-size, 21px);
			margin-bottom: 49px;
		}
		@media (max-width: 767px) {
			line-height: 22px;
			@include rem(font-size, 16px);
			margin-bottom: 32px;
		}
		
	}

	.wrap-btn {
		text-align: center;
	}
}

.transparent-btn {
	background-color: transparent;
	border: 2px solid #64A3D6;
	padding: 20px 31px;	
	color: white;
	display: inline-block;
	margin-right: 16px;
	font-weight: 500;
	line-height: 28px;
	cursor: pointer;
	@include rem(font-size, 21px);
	text-transform: uppercase;
	margin-bottom: 20px;	
	@media (max-width: 991px) {
		line-height: 18px;
		@include rem(font-size, 14px);
		padding: 14px 25px;
	}
	@media (max-width: 530px) {
		margin-right: 0;
	}
	&:last-of-type {
		margin-right: 0;
	}
	&:hover {
		background-color: #FFE7A1;
		color: black;
	}

	&.black {
		color: black;
	}

	&.with-arrow {
		position: relative;
		&:before {
			content:"";
			height: 100%;
			width: 9px;
			right: -30px;
			top: 0;
			position: absolute;
			background-image: url(../img/icons/arrow-down-2.svg);
			background-position: center center;
			background-repeat: no-repeat;
			@media (max-width: 991px) {
				background-position: center bottom;
			}
		}

		.max {
			display: none;
		}

		&[aria-expanded="true"] {
			.min {
				display: none;
			}
			.max {
				display: block;
			}
			&:before {
				background-image: url(../img/icons/arrow-up.svg);
			}
		}
	}

	&.inverse {
		color: black;
		background-color: #FFE7A1;
		&:hover {
			color: black;
			background-color: transparent;
		}
		&.white-h {
			&:hover {
				color: white;
			}
		}
	}
}

.box-title.white {
	color: white;
}



/* about-pageText
---------------------------------------------------------------------------*/
.about-pageText {
	padding-top: 100px;
  padding-bottom: 10px;
	@media (max-width: 991px) {
		padding-top: 50px;
    padding-bottom: 20px;
	}
	@media (max-width: 767px) {
    padding-bottom: 11px;
	}
}

.yellowBorder-box {
	padding: 64px 90px;
	border: 10px solid #FFE7A1;
	max-width: 945px;
	margin: 0 auto 130px;
	text-align: center;
	@include rem(font-size, 51px);
	line-height: normal;

	@media (max-width: 991px) {
		max-width: 520px;
		margin-bottom: 100px;
		@include rem(font-size, 27px);
		padding: 33px 24px;
		border: 8px solid #FFE7A1;
	}	

	@media (max-width: 767px) {
		max-width: 320px;
		margin-bottom: 50px;
		@include rem(font-size, 18px);
		padding: 21px 15px 29px 15px;
		border: 5px solid #FFE7A1;
	}
}

.problemBox {
	margin-bottom: 90px;
	@media (max-width: 991px) {
		margin-bottom: 67px;
	}
	@media (max-width: 767px) {
		margin-bottom: 20px;
		&:not(.text-box) {
			margin-bottom: 43px;
		}
	}

	@media(max-height:700px) and (min-width:992px) {
		margin-bottom: 30px;
	}

	&.text-box {
		font-weight: 300;
		line-height: 41px;
		@include rem(font-size, 30px);

		@media (max-width: 991px) {
			line-height: 29px;
			@include rem(font-size, 21px);
		}
		@media (max-width: 767px) {
			line-height: 25px;
			@include rem(font-size, 18px);
		}

		@media(max-height:700px) and (min-width:992px) {
      line-height: 32px;
			@include rem(font-size, 28px);
    }
	}
	.img-box {
		height: 100%;
		width: 100%;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		@media (max-width: 767px) {
			min-height: 240px;
		}
		@media(max-height:700px) and (min-width:992px) {
      min-height: 270px;
    }
	}
}

.wrap-list-box {
	padding-top: 40px;

	h3 {
		text-align: left;
		margin-bottom: 71px;
		@media (max-width: 991px) {
			margin-bottom: 34px;
		}
		@media (max-width: 767px) {
			margin-bottom: 17px;
		}

		@media(max-height:700px) and (min-width:992px) {
      margin-bottom: 34px;
    }
	}

}
.cicle-list {
	padding-left: 14px;
	@media (max-width: 991px) {
		padding-left: 5px;
	}
	li {
		position: relative;
		padding-left: 83px;
		line-height: 38px;
		@include rem(font-size, 27px);
    margin-bottom: 46px;
    @media (max-width: 991px) {
    	padding-left: 67px;
    }
    @media (max-width: 767px) {
    	line-height: 25px;
			@include rem(font-size, 18px);
			padding-left: 55px;
			margin-bottom: 31px;
		}
		
		@media(max-height:700px) and (min-width:992px) {
			@include rem(font-size, 20px);
			margin-bottom: 32px;
			line-height: 24px;
		}
		
		&:before {
			content:"";
			position: absolute;
			left: 0;
			top: -6px;
			width: 50px;
			height: 50px;
			// border-radius: 50%;
			// border: 5px solid #FFE9A3;
			// background: #fff;
			z-index: 2;
			@media (max-width: 767px) {
				width: 40px;
				height: 40px;
				top: -7px;
			}

			@media(max-height:700px) and (min-width:992px) {
				top: -13px;
			}
		}
		&:after {
			content:"";
			position: absolute;
			left: 22.5px;
			top: 40px;
			width: 5px;
			// height: 100%;
			// background: #FFE9A3;
			z-index: 1;
			@media (max-width: 767px) {
				left: 17.5px;
				top: 32px;
			}

			@media(max-height:700px) and (min-width:992px) {
				top: 34px;
			}
		}
		&:last-of-type:after {
			display: none;
		}
	}

	&.blue {
		li {
			padding-left: 99px;
			margin-bottom: 21px;
			@media (max-width: 991px) {
				padding-left: 67px;
			}
			@media (max-width: 767px) {
				padding-left: 56px;
		    margin-bottom: 14px;
			}

			@media(max-height:700px) and (min-width:992px) {
				margin-bottom: 34px;
			}

			&:before {
				border-color: $blue;
				@media (max-width: 767px) {
					top: -2px;
				}
			}
			&:after {
				display: none;
			}
		}
	}
}

/**/
.blueBorder-box {
	padding: 41px 90px 61px 90px;
	border: 10px solid #64A3D6;
	max-width: 945px;
	margin: 0 auto 130px;
	text-align: center;
	@include rem(font-size, 30px);
	line-height: 41px;

	@media (max-width: 991px) {
		max-width: 590px;
		margin-bottom: 100px;
		line-height: 37px;
		@include rem(font-size, 27px);
		padding: 33px 24px 30px 24px;
		border: 8px solid #64A3D6;
	}	

	@media (max-width: 767px) {
		max-width: 333px;
		margin-bottom: 50px;
		line-height: 25px;
		@include rem(font-size, 18px);
		padding: 18px 15px 19px 15px;
		border: 5px solid #64A3D6;
	}
}

/* key-skills-wrap
---------------------------------------------------------------------------*/
.key-skills-wrap {
	padding-bottom: 74px;
	@media (max-width: 991px) {
		padding-bottom: 50px;
	}
	@media (max-width: 767px) {
		padding-bottom: 20px;
	}

	.box-title {
    margin-bottom: 14px;
	}

	.after-title {
		font-weight: 300;
		line-height: 41px;
		@include rem(font-size, 30px);
		margin-bottom: 62px;	
		text-align: center;
		@media (max-width: 991px) {
			line-height: 29px;
			@include rem(font-size, 21px);
			margin-bottom: 49px;
		}
		@media (max-width: 767px) {
			line-height: 22px;
			@include rem(font-size, 16px);
			margin-bottom: 32px;
		}
		@media(max-height:700px) and (min-width:992px) {
			margin-bottom: 32px;
		}
	}

	.img-box {
    margin-bottom: 41px;
    min-height: 164px;
		@media (max-width: 991px) {
			max-width: 130px;
			min-height: 129px;
			margin-bottom: 30px;
		}
		img {
			max-width: 100%;
		}
		&.green {
			@media (max-width: 991px) {
				max-width: 106px;
			}
		}

		@media(max-height:700px) and (min-width:992px) {
			max-width: 130px;
			min-height: 129px;
			margin-bottom: 30px;

			&.green {
				img {
					max-height: 130px;
				}
			}
		}
	}

}

.skills-list {
	@media (max-width: 767px) {
		margin-bottom: 40px;
	}

	li {
		position: relative;
		padding-left: 30px;
		font-weight: 300;
		@include rem(font-size, 21px);
    line-height: 24px;
    margin-bottom: 19px;

    @media (max-width: 991px) {
    	line-height: 19px;
    	@include rem(font-size, 15px);
    	padding-left: 17px;
  		margin-bottom: 12px;
    }

		&:before {
			content: "";
			display: block;
			position: absolute;
			top: 5px;
			left: 0;
			width: 15px;
			height: 15px;
			@media (max-width: 991px) {
				width: 11px;
				height: 11px;
			}
		}
	}

	&.yellow li:before {
		background: #FFE9A3;
	}

	&.green li:before {
		background: #B1D588;
	}

	&.blue li:before {
		background: #64A3D6;
	}
}

/**/
.greenBorder-box {
	padding: 27px 40px 37px 40px;
	border: 10px solid #B1D588;
	max-width: 826px;
	margin: 0 auto 130px;
	text-align: center;
	@include rem(font-size, 30px);
	line-height: 41px;

	@media (max-width: 991px) {
		max-width: 595px;
		margin-bottom: 100px;
		line-height: 30px;
		@include rem(font-size, 22px);
		padding: 20px 24px 25px 24px;
		border: 8px solid #B1D588;
	}	

	@media (max-width: 767px) {
		max-width: 333px;
		margin-bottom: 60px;
		line-height: 25px;
		@include rem(font-size, 18px);
		padding: 21px 15px 19px 15px;
		border: 5px solid #B1D588;
	}
}

/* comments-wrap
---------------------------------------------------------------------------*/
.comments-wrap {
	padding-bottom: 130px;
	@media (max-width:991px) {
		padding-bottom: 80px;
	}
	@media (max-width:767px) {
		padding-bottom: 30px;
	}

	.box-title {
		@media (max-width:991px) {
			margin-bottom: 25px;
		}
		@media (max-width:767px) {
			margin-bottom: 39px;
		}
	}
	.img-box {
		position: relative;
		padding-top: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		border-radius: 50px;
		border: 8px solid #FFE9A3;
		margin-bottom: 84px;
		@media (max-width:991px) {
			margin-bottom: 51px;
		}
		@media (max-width:991px) {
	    height: 222px;
	    margin-bottom: 51px;
	    max-width: 223px;
	    padding: 0;
		}

		&:before {
			content: "";
	    position: absolute;
	    bottom: -63px;
	    right: 27%;
	    border: 31px solid transparent;
	    border-bottom: 14px solid #ffe9a3;
	    border-left: 77px solid #ffe9a3;
	    transform: rotate(117deg);
	    z-index: -1;
			@media (max-width:991px) {
				bottom: -39px;
    		right: 8%;
			}
		}

		&.green {
			border-color: #B1D588;
			&:before {
				border-bottom-color: #B1D588;
				border-left-color: #B1D588;
		    transform: rotate(48deg);
		    bottom: -48px;
		    right: 48%;
				@media (max-width:991px) {
					bottom: -19px;
    			right: 42%;
				}
			}
		}

		&.blue {
			border-color: $blue;
			&:before {
				border-bottom-color: $blue;
				border-left-color: $blue;
			}
		}

	}

	.name {
		font-weight: bold;
		line-height: 33px;
		@include rem(font-size, 21px);
		color: #000000;
		margin-bottom: 12px;
		@media (max-width:991px) {
			@include rem(font-size, 15px);
			margin-bottom: 5px;
		}
	}

	.wrap-links {
		margin-bottom: 16px;
		@media (max-width:767px) {
			margin-bottom: 13px;
		}
	}

	.fb, .mail {
		background: url(../img/icons/fb.svg) no-repeat left center;
		background-size: 24px;
		padding-left: 34px;
		color: #2280CB;
		font-weight: 300;
		line-height: 28px;
		@include rem(font-size, 21px);
		margin-bottom: 12px;
		display: block;
		@media (max-width:991px) {
			@include rem(font-size, 15px);
			line-height: 19px;
			background-size: 18px;
			padding-left: 30px;
		}
		span {
			border-bottom: 1px solid #2280CB;
			transition: all 0.2s linear;
		}
		&:hover {
			color:black;
			span {
				border-color: black;
			}
		}
	}
	.mail {
		background: url(../img/icons/mail.svg) no-repeat left center;
		@media (max-width:991px) {
			background-size: 18px;
		}
	}

	.text-box {
		line-height: 33px;
		font-weight: 300;
		@include rem(font-size, 21px);

		@media (max-width:991px) {
			@include rem(font-size, 15px);
			line-height: 23px;
		}
		@media (max-width:767px) {
	    margin-bottom: 36px;
		}

	}
}

/* history-box
---------------------------------------------------------------------------*/
.history-box {
	max-width: 968px;
  margin-bottom: 160px;
  padding-bottom: 49px;
  padding-top: 55px;
  position: relative;
  @media (max-width: 767px) {
  	padding-top: 31px;
  	padding-bottom: 24px;
  	margin-bottom: 68px;
  }

  .box-title {
  	margin-bottom: 32px;
  	@media (max-width: 991px) {
  		margin-bottom: 26px;
  	}
  	@media (max-width: 767px) {
  		margin-bottom: 10px;
  	}
  }

  &:before {
    content: "";
    border: 10px solid $blue;
    width: 400%;
    height: 100%;
    position: absolute;
    right: 311px;
    top: 0;
    z-index: -1;
  	@media (max-width: 991px) {
  		right: 155px;
  	}
  	@media (max-width: 767px) {
  		border: 5px solid $blue;
  		right: 48px;
  	}
	}
	 &:after {
    content: "";
    width: 20px;
    height: calc(100% - 210px);
    position: absolute;
    right: 308px;
    top: 105px;
    z-index: -1;
		background: #fff;
		@media (max-width: 991px) {
  		right: 152px;
  	}
  	@media (max-width: 767px) {
    	height: calc(100% - 160px);
  		right: 45px;
  		top: 47px;
  	}
	}

	p {
		font-weight: 300;
		line-height: 33px;
		@include rem(font-size, 21px);

		@media (max-width: 991px) {
			line-height: 23px;
			@include rem(font-size, 15px);
		}
		@media (max-width: 767px) {
			line-height: 25px;
			@include rem(font-size, 16px);
		}
	}

	.transparent-btn {
		margin-top: 30px;
		@media (max-width: 991px) {
			margin-top: 20px;
		}
		@media (max-width: 767px) {
			margin-top: 29px;
		}
	}
}

/* successful-youth-council
---------------------------------------------------------------------------*/
.successful-youth-councils {
	.box-title {
    margin-bottom: 27px;
	}
}

.wrap-councils-box {
	display: flex;
  flex-wrap: wrap;
  margin-bottom: 130px;
  @media (max-width: 767px) {
  	margin-bottom: 35px;
  }

  .councils-box {
  	width: calc(20% - 19px);
  	margin-right: 23px;
  	@media (max-width: 767px) {
			width: calc(50% - 12px);
			margin-bottom: 30px;
			&:nth-child(even) {
				margin-right: 0;
			}

  	}
  	&:last-of-type {
  		margin-right: 0;
  	}

  	.img-box {
  		margin-bottom: 15px;
  		img {
  			max-width: 100%;
  			@media (max-width: 767px) {
  				width: 100%;
  			}
  		}
  	}

  	.name {
			color: #000;
  		line-height: normal;
			@include rem(font-size, 22px);
			@media (max-width: 1199px) {
				@include rem(font-size, 15px);
			}
  	}
  }
}

/* how-create
---------------------------------------------------------------------------*/
.how-create {

	.box-title {
		margin-bottom: 55px;
		text-transform: none;
		@media (max-width: 991px) {
			margin-bottom: 39px;
		}
		@media (max-width: 767px) {
			margin-bottom: 26px;
		}
	}

	.after-title {
		font-weight: 300;
		line-height: 41px;
		@include rem(font-size, 30px);
		margin-bottom: 24px;	
		text-align: center;
		@media (max-width: 991px) {
			line-height: 29px;
			@include rem(font-size, 21px);
	    margin-bottom: 36px;
		}
		@media (max-width: 767px) {
			line-height: 22px;
			@include rem(font-size, 16px);
			margin-bottom: 23px;
		}
	}
}


/* training-course
---------------------------------------------------------------------------*/
.after-title.classic{
	font-weight: 300;
	line-height: 41px;
	@include rem(font-size, 30px);
	margin-bottom: 63px;	
	text-align: center;
	@media (max-width: 991px) {
		line-height: 29px;
		@include rem(font-size, 21px);
    margin-bottom: 26px;
	}
	@media (max-width: 767px) {
		line-height: 22px;
		@include rem(font-size, 16px);
		margin-bottom: 22px;
	}
}

blockquote {
	@include rem(font-size, 30px);
	font-style: italic;
	font-weight: 300;
	line-height: 41px;
	margin-bottom: 39px;
	margin-top: 14px;
	text-align: center;

	@media (max-width: 991px) {
		@include rem(font-size, 21px);
		line-height: 29px;
		margin-bottom: 18px;
		margin-top: 19px;
	}
	@media (max-width: 767px) {
		line-height: 22px;
		@include rem(font-size, 16px);
		margin-bottom: 16px;
    margin-top: 17px;
	}
}

.training-course {
	padding-bottom: 49px;
	@media (max-width: 991px) {
		padding-bottom: 81px;
	}
	@media (max-width: 767px) {
		padding-bottom: 0;
	}

	.box-title {
		margin-bottom: 18px;
		@media (max-width: 991px) {
			margin-bottom: 14px;
		}
		@media (max-width: 767px) {
			margin-bottom: 13px;
		}
	}

}

/* wrap-img-youth
---------------------------------------------------------------------------*/
.wrap-img-youth {
	margin-bottom: 125px;
	@media (max-width: 991px) {
		margin-bottom: 94px;
	}
	@media (max-width: 767px) {
		margin-bottom: 48px;
	}
	img {
		width: 100%;
	}
}

/* green-border-wrap
---------------------------------------------------------------------------*/
.green-border-wrap {
	max-width: 968px;
  margin-bottom: 100px;
  padding-bottom: 119px;
  padding-top: 55px;
  position: relative;
  @media (max-width: 991px) {
  	padding-bottom: 60px;
  	padding-top: 41px;
  	margin-bottom: 97px;
  }
  @media (max-width: 767px) {
  	padding-top: 31px;
  	padding-bottom: 16px;
  	margin-bottom: 58px;
  }

  &:before {
    content: "";
    border: 10px solid $green;
    width: 400%;
    height: 100%;
    position: absolute;
    right: 222px;
    top: 0;
    z-index: -1;
  	@media (max-width: 991px) {
  		right: 148px;
  		border-width: 8px;
  	}
  	@media (max-width: 767px) {
  		border: 5px solid $green;
  		right: 110px;
  	}
	}
	 &:after {
    content: "";
    width: 20px;
    height: calc(100% - 210px);
    position: absolute;
    right: 220px;
    top: 105px;
    z-index: -1;
		background: #fff;
		@media (max-width: 991px) {
  		right: 146px;
  		top: 73px;
  		height: calc(100% - 115px);
  	}
  	@media (max-width: 767px) {
	    height: calc(100% - 105px);
  		right: 108px;
  		top: 47px;
  	}
	}

	&.right {
		max-width: 968px;
		text-align: right;
    padding-top: 85px;
    padding-bottom: 72px;
    margin-bottom: 117px;

	  @media (max-width: 991px) {
	  	max-width: 600px;
	  	padding-bottom: 66px;
	  	margin-bottom: 96px;
	  }
	  @media (max-width: 767px) {
	  	max-width: 334px;
	  	padding-top: 43px;
	    padding-bottom: 43px;
	    margin-bottom: 50px;
	  }

		&:before {
	    right: auto;
	    left: 344px;
	  	@media (max-width: 991px) {
	  		left: 166px;
	  	}
	  	@media (max-width: 767px) {
	  		left: 152px;
	  	}
		}
		 &:after {
	    right: auto;
	    left: 340px;
      top: 71px;
      height: calc(100% - 141px);
			@media (max-width: 991px) {
	  		left: 163px;
	  		top: 59px;
	  		height: calc(100% - 118px);
	  	}
	  	@media (max-width: 767px) {
	    	height: calc(100% - 72px);
	  		left: 150px;
	  		top: 31px;
	  	}
		}
	}

	.box-title {
		margin-bottom: 45px;
		@media (max-width: 991px) {
			margin-bottom: 24px;
		}
		@media(max-height:700px) and (min-width:992px) {
      margin-bottom: 30px;
    }
	}

	.skills-list {
		li {
			line-height: 41px;
			@include rem(font-size, 30px);
			padding-left: 37px;
			margin-bottom: 26px;
			@media (max-width: 991px) {
				line-height: 29px;
				@include rem(font-size, 21px);
				padding-left: 24px;
		    margin-bottom: 17px;
			}
			@media (max-width: 991px) {
				line-height: 22px;
				@include rem(font-size, 16px);
				padding-left: 24px;
		    margin-bottom: 17px;
			}
			@media(max-height:700px) and (min-width:992px) {
				margin-bottom: 10px;
				@include rem(font-size, 26px);
			}
			&:before {
				top: 12px;
				@media (max-width: 991px) {
					top: 8px;
				}
				@media (max-width: 767px) {
					top: 5px;
				}
			}
		}
	}

	.text-box {
		font-weight: 300;
		line-height: 41px;
		@include rem(font-size, 30px);
		text-align: right;
	  @media (max-width: 991px) {
	  	line-height: 29px;
	  	@include rem(font-size, 21px);
	  }
	  @media (max-width: 767px) {
	  	line-height: 22px;
	  	@include rem(font-size, 16px);
	  }
	}
}

/* effective-course
---------------------------------------------------------------------------*/
.effective-course {
	padding-bottom: 140px;
	@media (max-width: 991px) {
    padding-bottom: 68px;
	}
	@media (max-width: 767px) {
		padding-bottom: 38px;
	}

	@media(max-height:700px) and (min-width:992px) {
		padding-bottom: 40px;
	}

	.box-title {
		margin-bottom: 57px;
	  @media (max-width: 991px) {
	  	margin-bottom: 33px;
		}
		
		@media(max-height:700px) and (min-width:992px) {
      margin-bottom: 33px;
    }
	}
}

/* get-skills-wrap
---------------------------------------------------------------------------*/
.get-skills-wrap {
	padding-bottom: 86px;
	@media (max-width: 991px) {
		padding-bottom: 55px;
	}
	@media (max-width: 767px) {
		padding-bottom: 81px;
	}

	.box-title {
		margin-bottom: 66px;
		@media (max-width: 991px) {
			margin-bottom: 28px;
		}
		@media (max-width: 991px) {
			margin-bottom: 40px;
		}

		@media(max-height:700px) and (min-width:992px) {
			margin-bottom: 28px;
			@include rem(font-size, 36px);
			line-height: 46px;
    }
	}

	.img-box {
		width: 164px;
		height: 147px;
		margin: 0 auto 30px;
		text-align: center;
		@media (max-width: 991px) {
			width: 103px;
			height: 89px;
			margin-bottom: 21px;
		}
		@media(max-height:700px) and (min-width:992px) {
      height: 120px;
    }
		img {
			max-width: 100%;
			@media (max-width: 991px) {
				max-height: 89px;
			}

			@media(max-height:700px) and (min-width:992px) {
				max-height: 140px;
			}
		}
	}

	.title {
		line-height: 30px;
		@include rem(font-size, 22px);
		text-align: center;
		@media (max-width: 991px) {
			line-height: 21px;
			@include rem(font-size, 15px);
		}
	}

	.get-skills-box {
		margin-bottom: 60px;
		@media (max-width: 991px) {
			margin-bottom: 36px;
		}
		@media (max-width: 767px) {
			margin-bottom: 31px;
		}
		@media(max-height:700px) and (min-width:992px) {
      margin-bottom: 30px;
    }
	}
}

/* comments-wrap trainer
 ---------------------------------------------------------------------------*/
.comments-wrap.trainer {
	@media (max-width: 991px) {
		padding-bottom: 70px;
	}
	@media (max-width: 767px) {
		padding-bottom: 43px;
	}
	
	.box-title {
		margin-bottom: 70px;
		@media (max-width: 991px) {
			margin-bottom: 21px;
		}
		@media (max-width: 767px) {
			margin-bottom: 25px;
		}
	}

	.img-box {
		margin-bottom: 20px;
		border-color: $green;
		@media (max-width: 991px) {
			border-width: 5px;
			max-height:180px;
			border-radius: 25px;
		}
		@media (max-width: 767px) {
			max-height:170px;
			margin-bottom: 7px;
		}
		&:before {
			display: none;
		}
		&.yellow {
			border-color: $yellow;
		}
		&.blue {
			border-color: $blue;
		}
	}

	.name {
		@media (max-width: 767px) {
			line-height: 30px;
		}
	}

	.wrap-links {
		@media (max-width: 991px) {
	    margin-bottom: 9px;
	  }
	}

	.fb, .mail {
		@media (max-width: 767px) {
	    margin-bottom: 9px;
	    @include rem(font-size, 14px);
	    line-height: 18px;
	  }
	}

	.text-box {
		@media (max-width: 767px) {
	    margin-bottom: 23px;
	  }
	}
}

/* order-training
---------------------------------------------------------------------------*/
.order-training {
	padding-bottom: 36px;
	@media (max-width: 991px) {
		padding-bottom: 39px;
	}
	@media (max-width: 767px) {
		padding-bottom: 13px;
	}
	.box-title {
		margin-bottom: 52px;
		@media (max-width: 991px) {
			margin-bottom: 20px;
		}
	}
}

/* wrap-download-link
---------------------------------------------------------------------------*/
.wrap-download-link {
	margin-left: -29px;
  margin-bottom: -15px;
  padding-top: 15px;
  @media (max-width: 991px) {
  	margin-left: -16px;
  	margin-right: -13px;
  }
}
.download-link {
	background-color: #ffe9a3;
	background-image: url(../img/icons/download.svg);
	background-repeat: no-repeat;
	background-position: 10px center;
	min-height: 50px;
	cursor: pointer;
	width: 84%;
	font-weight: 600;
  padding-top: 14px;
  padding-bottom: 10px;
  font-size: 14px;
  text-transform: uppercase;
  color: #000 !important;
  display: block;
  padding-left: 60px;
  margin-bottom: 10px;
  @media (max-width: 991px) {
  	width: 100%;
  }
}

.wrap-steps .wrap-accardion:nth-child(3n) {
	.download-link {
		background-color: $green;
	}
}

.wrap-steps .wrap-accardion:nth-child(3n+1) {
	.download-link {
		background-color: $blue;
	}
}